import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

export class ContactUsMessage {
    uuid;
    date;
    authorEmail;
    message;
 
    constructor(authorEmail,message) {
        this.uuid = uuidv4();
        this.date = firebase.firestore.FieldValue.serverTimestamp();
        this.authorEmail = authorEmail;
        this.message = message;
    }
}


