<script>
import {FormValidatorsMixin} from '../../../mixins/form-validators-mixin.js'
import InputValidatorsComponent from '../../shared-component/input-validators-component/input-validators-component.vue'
import MessagesService from '../../../services/MessagesService'
import {ContactUsMessage} from '../../../model/ContactUsMessage.js'
import ModalInfoComponent from '../../shared-component/modal-info-component/modal-info-component.vue'
import router from '../../../router'

export default {
    name: "ContactPage",
      props: ['templateMode'],
    created(){
         this.$emit('headerState', 10);
    }, 
    components: {
        InputValidatorsComponent,ModalInfoComponent
    },
    mixins: [FormValidatorsMixin],
    data() {
        return {
             informationOnlyText: '',
            sendingMessage: false,
            form: {
                email: "",
                message:"",
            },
             formValid: {
                email: false,
            },
        }
    },
    methods: {
        messageChanged(){
            console.info("message changed :");
        },
        gePageStyle(){
            if (this.templateMode < 3){
                return "max-width:500px; margin: 0 auto;position:relative;";
            } else {
                return "";
            }
        },
        sendMessage(){
            console.info("sending message...");
            this.sendingMessage = true;

            let message = new ContactUsMessage(this.form.email, this.form.message);

            MessagesService.sendContactUsMessage(message).then(()=>{
                this.informationOnlyText = "Votre message a été envoyé avec succès. Showroombaby vous répondra par email dans les plus brefs délais.";
                this.$nextTick(() => {
                    this.sendingMessage = false;
                    this.$refs.informationOnly.showModal();
                });
            });

        },
        isFormOk(){
            return this.formValid.email && this.form.email.length > 4 && this.form.message.length > 1;
        },
        okInformationOnly(){
             console.info("okInformationOnly");
              router.push('/');
        }
    }
};
</script>

<style lang="css">
    @import "contact-page.css";
</style>

<template src="./contact-page.html"></template>